/* eslint-disable max-len */
import {
  RISK_SECTION_HIGH,
  RISK_SECTION_LOW,
  RISK_SECTION_MEDIUM,
} from '@consts/risk';
import {
  formatOption,
  formatQuestion,
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';
import { COL_SIZE_FULL } from '@consts/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 0, 3),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 4, 99, 0, 3),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 4, 99, 4, 99),
];

export const resultSettings = [
  formatResult(
    'En traitant au plus tôt le problème, vous pourrez récupérer plus rapidement et réduire tout risque de développer des problèmes plus importants. Avec Joint Academy, vous pouvez dès maintenant obtenir une évaluation et un traitement adapté.',
    'D’après vos réponses, vos douleurs dans le bas du dos vous causent une légère gêne dans votre vie quotidienne. Toutefois, il est toujours bien de faire évaluer votre douleur dans les temps. À un stade précoce, le développement des douleurs articulaires peut être ralenti et on peut réduire de manière proactive le risque de problèmes sur le long terme. Traiter le problème rapidement vous donnera aussi les meilleures chances de profiter d’une vie active et sans douleur. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs dorsales combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre dos, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans le bas du dos et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.',
    0,
    3,
  ),
  formatResult(
    'Mais pas d’inquiétude ! En traitant vos douleurs lombaires à temps, vous réduirez le risque de développer des problèmes sur le long terme. Avec Joint Academy, vous pouvez dès maintenant obtenir un traitement adapté.',
    'D’après vos réponses, vos douleurs dans le bas du dos vous causent une gêne modérée et semblent avoir un impact négatif dans votre quotidien. Avec des soins adaptés, vous pouvez vous sentir mieux et faire à nouveau les activités que vous aimez. Démarrer votre traitement à un stade précoce permettra de ne pas mettre plus de tension sur vos articulations et de réduire vos douleurs plus rapidement. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs dorsales combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre dos, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans le bas du dos et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.',
    4,
    99,
    0,
    3,
  ),
  formatResult(
    'Mais pas d’inquiétude ! Vous pouvez traiter vos douleurs lombaires pour profiter à nouveau des activités que vous aimez. Avec Joint Academy, vous pouvez dès maintenant obtenir un traitement adapté.',
    'D’après vos réponses, vous êtes fortement gêné par votre douleur dans le bas du dos et vous avez un risque élevé de développer des problèmes sur le long terme. Avec des soins adaptés, vous pouvez vous sentir mieux et vivre à nouveau avec moins de douleurs et plus de mobilité. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs dorsales combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre dos, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans le bas du dos et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.',
    4,
    99,
    4,
    99,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Pendant les 2 dernières semaines, votre mal de dos s’est-il étendu à une ou deux de vos jambes ?',
  ),
  formatYesNoQuestion(
    'question2',
    'Pendant les 2 dernières semaines, avez-vous ressenti une douleur dans votre épaule ou dans votre cou ?',
  ),
  formatYesNoQuestion(
    'question3',
    'Votre mal de dos vous empêche-t-il de marcher sur de longues distances ?',
  ),
  formatYesNoQuestion(
    'question4',
    'Pendant les deux dernières semaines, avez-vous mis plus de temps à vous habiller à cause de votre mal de dos ?',
  ),
  formatYesNoQuestion(
    'question5',
    'Pensez-vous qu\'il est dangereux d\'être physiquement actif pour une personne dans votre situation ?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question6',
    'Des pensées anxieuses vous traversent-elles souvent l\'esprit ?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question7',
    'Avez-vous l\'impression que votre mal de dos est terrible et que vous n\'irez jamais mieux ?',
    1,
    0,
    1,
  ),
  formatYesNoQuestion(
    'question8',
    'En général, est-ce qu\'il y a des choses que vous ne pouvez plus apprécier comme avant ?',
    1,
    0,
    1,
  ),
  formatQuestion(
    'question9',
    'Globalement, à quel point votre mal de dos a-t-il été gênant au cours des deux dernières semaines ?',
    [
      formatOption('Pas du tout', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Un peu', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Moyennement', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Beaucoup', 1, 1, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Extrêmement', 1, 1, COL_SIZE_FULL, COL_SIZE_FULL),
    ],
  ),
];

export const references = [
  'Hill JC, Dunn KM, Lewis M, et al. A primary care back pain screening tool: identifying patient subgroups for initial treatment. Arthritis Rheum. May 15 2008;59(5):632-641.',
  'Hill JC, Dunn KM, Main CJ, Hay EM. Subgrouping low back pain: a comparison of the STarT Back Tool with the Orebro Musculoskeletal Pain Screening Questionnaire. Eur J Pain. Jan 2010;14(1):83-89.',
  'Hill JC, Vohora K, Dunn KM, Main CJ, Hay EM. Comparing the STarT back screening tool’s subgroup allocation of individual patients with that of independent clinical experts. Clin J Pain. Nov-Dec 2010;26(9):783-787.',
  'Hill JC, Whitehurst DG, Lewis M, et al. Comparison of stratified primary care management for low back pain with current best practice (STarT Back): a randomised controlled trial. Lancet. Oct 29 2011;378(9802):1560-1571.',
  'Fritz JM, Beneciuk JM, George SZ. Relationship between categorization with the STarT Back Screening Tool and prognosis for people receiving physical therapy for low back pain. Physical therapy. May 2011;91(5):722-732.',
];
